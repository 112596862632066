import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, of, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { AlertService } from '../modules/alerts/services/alerts.service';
import { AlertParams } from '../modules';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(private alertService: AlertService) {}

  snackbarOnError<T>(newValue?: any): MonoTypeOperatorFunction<T> {
    return catchError((error) => {
      this.alertService.showApiError({ error });
      console.error('ErrorHandlerService snackbarOnError:', error);
      return newValue !== undefined ? of(newValue) : throwError(() => error);
    });
  }

  snackbarOnErrorWithMessage<T>(alert: AlertParams): MonoTypeOperatorFunction<T> {
    return catchError((error) => {
      this.alertService.showApiError(alert);
      console.error('ErrorHandlerService snackbarOnError:', error);
      return throwError(() => error);
    });
  }

  run<T>(functionToRun: (error: any) => void): MonoTypeOperatorFunction<T> {
    return catchError((error) => {
      functionToRun(error);
      return throwError(() => error);
    });
  }

  returnValue<T>(newValue: any | null = []): MonoTypeOperatorFunction<T> {
    return catchError((error) => {
      console.error('ErrorHandlerService returnValue:', error);
      return of(newValue);
    });
  }

  returnValueOn404<T>(newValue: any | null = [], showSnackbar = false): MonoTypeOperatorFunction<T> {
    return this.returnValueOnStatus([404], newValue, showSnackbar);
  }

  returnValueOnStatus<T>(
    statuses: number[],
    newValue: any | null = [],
    showSnackbar = false
  ): MonoTypeOperatorFunction<T> {
    return catchError((error) => {
      console.error('ErrorHandlerService returnValueOn404:', error);

      if (statuses.includes(error.status)) {
        if (showSnackbar) {
          this.alertService.showApiError({ error });
        }

        return of(newValue);
      }

      return throwError(() => error);
    });
  }

  retryAfter<T>(delayMs: number, occurrences = 1): MonoTypeOperatorFunction<T> {
    let retries = 0;
    return retryWhen((errors) => {
      return errors.pipe(
        mergeMap((error) => {
          if (retries < occurrences) {
            retries++;
            return timer(delayMs);
          }
          return throwError(() => error); // Throw an error after x retries
        })
      );
    });
  }
}
