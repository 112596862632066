import { formatDate } from '@angular/common';
import { format, isValid, parse } from 'date-fns';

export class LtlConnectDateHelper {
  static formatToPortlandDateTimeString(date: Date): string {
    // TODO:Check if it should be replaced by the DateHelper.localDocking method
    if (!isValid(date)) {
      return null;
    }
    return formatDate(date, 'yyyy-MM-ddTHH:mm:ss', 'en-US', 'America/Los_Angeles');
  }

  static formatToPortlandHourMinString(date: Date): string {
    // TODO: Check if it should be replaced by the DateHelper.localDocking method
    return formatDate(date, 'HH:mm', 'en-US', 'America/Los_Angeles');
  }

  /**  formats to yyyy-MM-dd */
  static formatToApiDate(date: number | Date): string | undefined {
    return date ? format(date, 'yyyy-MM-dd') : undefined;
  }

  static apiDateToDate(date: string): Date | undefined {
    return parse(date, 'yyyy-MM-dd', new Date());
  }
}
