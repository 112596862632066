import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOTNET_API_URI, USER_MGMT_PROXY_API_URI } from '../tokens';
import { AccessLevels } from './enums/user-management.enums';
import {
  AddAccountPartnerRequest,
  AddAccountPartnerResponse,
  PmBaseResponse,
  BetaTestingRequest,
  BetaTestingResponse,
  LocationUser,
  Role,
  UpdateUserProfileRequest,
  UserAccess,
  UserDetailsRequest,
  UserDetailsResponse,
  UserProfileResponse,
} from './interfaces/user-management.interface';

@Injectable({
  providedIn: 'root',
})
export class UserManagementApiService {
  constructor(
    @Inject(DOTNET_API_URI) private baseApiUri: string,
    @Inject(USER_MGMT_PROXY_API_URI) private baseApiProxyUri: string,
    private httpClient: HttpClient
  ) {}

  get apiUri(): string {
    return `${this.baseApiProxyUri}user-management/backend/`;
  }

  get backendMasterUri(): string {
    return `${this.baseApiUri}partner-master/backend/`;
  }

  get adminRoleCode(): number {
    return AccessLevels.FULL_ACCESS;
  }

  getUserAccessDetails(): Observable<Array<UserAccess>> {
    const url = `${this.apiUri}user-management/getUserAccessDetails`;
    return this.httpClient.get<Array<UserAccess>>(url);
  }

  getPartnerByUser(): Observable<Array<LocationUser>> {
    const url = `${this.apiUri}user-management/getPartnerByUser`;
    return this.httpClient.get<Array<LocationUser>>(url);
  }

  getAllAccessLevels(): Observable<Array<Role>> {
    const url = `${this.apiUri}user-management/getAllAccessLevels`;
    return this.httpClient.get<Array<Role>>(url);
  }

  upsert(request: UserAccess): Observable<string> {
    const url = `${this.apiUri}user-management/upsert`;
    return this.httpClient.post<string>(url, request, {
      responseType: <any>'text',
    });
  }

  updateUserProfile(request: Partial<UpdateUserProfileRequest>): Observable<UserDetailsResponse> {
    const url = `${this.apiUri}partner/UpdateUserProfile`;
    return this.httpClient.put<UserDetailsResponse>(url, request);
  }

  userHasAdminAccess(): Observable<boolean> {
    const accessLevels: number[] = [];

    return this.getPartnerByUser().pipe(
      map((response: LocationUser[]) => {
        response.filter((x) => {
          x.accessLevel.filter((x) => accessLevels.push(x.roleId)),
            x.children.filter((x) => x.accessLevel.filter((x) => accessLevels.push(x.roleId)));
        });

        return accessLevels.includes(this.adminRoleCode);
      })
    );
  }

  getWebClient(username: string): Observable<string> {
    const url = `${this.apiUri}partner/GetWebClient/${encodeURI(username)}`;
    return this.httpClient.get<string>(url, {
      responseType: <any>'text',
    });
  }

  getResetLink(username: string): Observable<string> {
    return this.httpClient.get<string>(
      `${this.baseApiProxyUri}user-management/backend/partner/ResetPasswordLink/${username}`,
      {
        responseType: <any>'text',
      }
    );
  }

  // TODO: Check if there is a put for this to update the user
  getUserProfile(): Observable<UserProfileResponse> {
    const url = `${this.apiUri}partner/userprofile`;
    return this.httpClient.get<UserProfileResponse>(url).pipe(
      map((user) => {
        user.preferredLanguageCode = user.preferredLanguageCode?.trim();
        return user;
      })
    );
  }

  createUserDetails(request: UserDetailsRequest) {
    const url = `${this.apiUri}partner/createUser`;
    return this.httpClient.post<PmBaseResponse>(url, request);
  }

  addAccount(account: AddAccountPartnerRequest): Observable<AddAccountPartnerResponse> {
    const url = `${this.apiUri}partner/addAccount`;
    return this.httpClient.put<AddAccountPartnerResponse>(url, account);
  }

  getUser(userName: string): Observable<UserDetailsResponse> {
    const url = `${this.backendMasterUri}users/${userName}`;
    return this.httpClient.get<UserDetailsResponse>(url);
  }

  getFeature(featureName: string) {
    const url = `${this.apiUri}partner/getFeature/${featureName}`;
    return this.httpClient.get<BetaTestingRequest[]>(url);
  }

  addUserFeature(request: BetaTestingResponse): Observable<PmBaseResponse> {
    const url = `${this.apiUri}partner/addUserFeatures`;
    return this.httpClient.put<PmBaseResponse>(url, request);
  }
}
